import { quoteConstants } from '../_constants';

const initialState = {
    loading: false,
    openQuotes: [],
    pastQuotes: [],
    quoteDetails: null,
    quoteComparisonInfo: null
};

export function quote(state = initialState, action) {
    switch(action.type) {
        case quoteConstants.SEND_QUOTE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case quoteConstants.SEND_QUOTE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.SEND_QUOTE_FAILURE:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.GET_OPEN_QUOTES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case quoteConstants.GET_OPEN_QUOTES_SUCCESS:
            return {
                ...state,
                loading: false,
                openQuotes: action.openQuotes
            };
        case quoteConstants.GET_OPEN_QUOTES_FAILURE:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.GET_PAST_QUOTES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case quoteConstants.GET_PAST_QUOTES_SUCCESS:
            return {
                ...state,
                loading: false,
                pastQuotes: action.pastQuotes
            };
        case quoteConstants.GET_PAST_QUOTES_FAILURE:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.GET_QUOTE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                quoteDetails: null
            };
        case quoteConstants.GET_QUOTE_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                quoteDetails: action.quoteDetails
            };
        case quoteConstants.GET_QUOTE_DETAILS_FAILURE:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.UPDATE_QUOTE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case quoteConstants.UPDATE_QUOTE_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.UPDATE_QUOTE_DETAILS_FAILURE:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.GET_QUOTE_COMPARISON_INFO_REQUEST:
            return {
                ...state,
                loading: true
            };
        case quoteConstants.GET_QUOTE_COMPARISON_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                quoteComparisonInfo: action.quoteComparisonInfo
            };
        case quoteConstants.GET_QUOTE_COMPARISON_INFO_FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state
    }
}