import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { quoteActions, loadActions } from '../../../_actions';
import { compareUtil } from '../../../_helpers';
import { numberWithCommas, formatShipmentType } from '../../../_helpers';
import { addCapSpace } from '../../../_helpers';
import NumericFormatCustom from '../../../components/NumericFormatCustom';
import { useParams } from "react-router-dom";
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import backgroundImage from '../../../_resources/home_page_background.jpg';
import NavMenu from '../../../components/NavMenu';
import PriceIndicatorBar from '../../../components/PriceIndicatorBar';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import quoteComparison from '../../../_resources/quote-comparison.jpg';

const classes = {
    contentContainer: {
        width: '100%',
        paddingLeft: 5,
        paddingRight: 5
    },
    paperStyle: {
        padding: 2
    },
    pageHeader: {
        fontWeight: 'bold',
        color: '#2D54DA'
    },
    loadDetailsHeader: {
        fontWeight: 'bold',
        color: '#2D54DA',
        marginBottom: 3
    },
    dividerStyle: {
        marginTop: 3,
        marginBottom: 3
    },
    formInput: {
        width: '100%'
    },
    selectInput: {
		width: '100%',
		marginTop: 2,
	},
    datePickerStyle: {
        marginTop: 2,
    },
    circularProgressStyle: {
        marginLeft: '45%', 
        marginTop: 2
    },
    confirmationTextStyle: {
        marginLeft: 1,
        marginTop: 2,
        fontSize: 20
    },
    submitButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        marginTop: 2,
        marginLeft: '40%',
    },
    locationListStyle: {
        paddingLeft: 1,
        paddingTop: 1
    },
};

const validationSchema = Yup.object().shape({
    bestBasePrice: Yup.number()
        .test(
            "is-positive",
            "Please enter a positive number",
            (value) => value >= 0
        )
        .typeError('Please enter a number'),
    fuelSurcharge: Yup.number()
        .test(
            "is-positive",
            "Please enter a positive number",
            (value) => value >= 0
        )
        .typeError('Please enter a number'),
    totalDropFee: Yup.number()
        .test(
            "is-positive",
            "Please enter a positive number",
            (value) => value >= 0
        )
        .typeError('Please enter a number'),
    currency: Yup.string()
        .required('Currency is required'),
    dateEquipmentAvailable: Yup.date()
        .required('Date is required')
        .typeError('Date is required'),
    equipmentType: Yup.string()
        .required('Equipment type is required'),
    trailerType: Yup.string()
        .required('Trailer type is required'),
    transitDays: Yup.number()
        .test(
            "is-positive",
            "Please enter a positive number",
            (value) => value >= 0
        )
        .typeError('Please enter a number'),
});

function EditQuotePage() {
    let { quoteId } = useParams();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const loading = useSelector(state => state.quote.loading);
    const quoteDetails = useSelector(state => state.quote.quoteDetails);
    const quoteComparisonInfo = useSelector(state => state.quote.quoteComparisonInfo);
    const loadingLoadDetails = useSelector(state => state.load.loading);
    const loadDetails = useSelector(state => state.load.loadDetails);

    const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(validationSchema),
        //defaultValues: { 'bestBasePrice': 0, 'fuelSurcharge': 0, 'totalDropFee': 0 }
    });

    const bestBasePrice = useWatch({control, name:'bestBasePrice'});
    const fuelSurcharge = useWatch({control, name:'fuelSurcharge'});
    const totalDropFee = useWatch({control, name:'totalDropFee'});

    useEffect(() => { 
		document.title = "DFQ Vendor - Edit Quote";
        dispatch(quoteActions.getQuoteDetails(quoteId));
        dispatch(quoteActions.getQuoteComparisonInfo(quoteId));
	}, []);

    useEffect(() => { 
        if(quoteDetails){
            setValue('bestBasePrice', quoteDetails.BestPrice);
            setValue('fuelSurcharge', quoteDetails.TotalSurcharges);
            setValue('totalDropFee', quoteDetails.ExtraFees);
            dispatch(loadActions.getLoadDetails(quoteDetails.LoadId));
        }
	}, [quoteDetails]);

    const onSubmit = data => {
        data.quoteId = quoteId;
        data.totalQuote = Number(bestBasePrice) + Number(fuelSurcharge) + Number(totalDropFee);
        if(!data.yourComments){
            data.yourComments = " ";
        }
        dispatch(quoteActions.updateQuoteDetails(data));
    };

    const openModal = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box display="flex" style={{backgroundColor: '#F2F5FD'}}>
            <CssBaseline />
            <NavMenu />
            <Container maxWidth={false} disableGutters sx={{ marginLeft: 2, marginTop: 2, marginRight: 2 }}>
                <Container maxWidth={false} disableGutters sx={classes.contentContainer}>
                    <Grid container spacing={1}>
                        {!loadingLoadDetails && loadDetails ?
                        <Grid item xs={12} sm={4}>
                            <Paper elevation={3} sx={classes.paperStyle}>
                                <Typography variant='h5' sx={classes.loadDetailsHeader}>Load Details</Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        Load ID:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {loadDetails.LoadId}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Quote By:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {dayjs(loadDetails.QuoteBy).format('MM/DD/YYYY h:mm A')}
                                    </Grid>
                                    {loadDetails.PickupList.map((pickup, index) =>{
                                        return(
                                            <Grid container key={pickup} spacing={1} sx={classes.locationListStyle}>
                                                <Grid item xs={12} sm={6}>
                                                    Pickup #{index + 1}:
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    {pickup}
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                    {loadDetails.DeliveryList.map((delivery, index) =>{
                                        return(
                                            <Grid container key={delivery} spacing={1} sx={classes.locationListStyle}>
                                                <Grid item xs={12} sm={6}>
                                                    Delivery #{index + 1}:
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    {delivery}
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                    <Grid item xs={12} sm={6}>
                                        Crossing Border:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {loadDetails.CrossingBorder ? 'Yes' : 'No'}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Shipment Type:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {formatShipmentType(loadDetails.ShipmentType)}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Total Pallets:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {numberWithCommas(loadDetails.TotalPallets)}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Total Weight:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {numberWithCommas(loadDetails.TotalWeight)}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Equipment Requested:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {addCapSpace(loadDetails.EquipmentRequested)}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Trailer Requested:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {addCapSpace(loadDetails.TrailerRequested)}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        First Pickup Date:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {dayjs(loadDetails.FirstPickupDate).format('MMM DD, YYYY')}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Last Delivery Date:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {dayjs(loadDetails.LastDeliveryDate).format('MMM DD, YYYY')}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Pallet Dimensions:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {loadDetails.PalletDimensions}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Quoting Comments:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {loadDetails.QuotingComments}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        : null}
                        <Grid item xs={12} sm={8}>
                            <Paper elevation={3} sx={classes.paperStyle}>
                                <Grid container sx={{ marginBottom: 5 }}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='h5' sx={classes.pageHeader}>Edit Quote Details</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} sx={{ paddingRight: 3 }}>
                                        {quoteComparisonInfo && quoteComparisonInfo.NumberOfQuotes >= 5 ?
                                            <Container>
                                                <IconButton size="small" aria-label="close" color="inherit" onClick={openModal} sx={{float: 'right'}}>
                                                    <HelpOutlineIcon sx={{color: '#2D54DA'}}/>
                                                </IconButton>
                                                <PriceIndicatorBar 
                                                    lowerBound={quoteComparisonInfo.LowerBound} 
                                                    upperBound={quoteComparisonInfo.UpperBound} 
                                                    userQuote={quoteComparisonInfo.UserQuotePrice}
                                                />
                                            </Container>
                                        : null}
                                    </Grid>
                                </Grid>
                                {quoteDetails ? 
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name="bestBasePrice"
                                                control={control}
                                                defaultValue={quoteDetails.BestPrice}
                                                render={({ field }) => 
                                                    <TextField 
                                                        label="Your Best Base Price" 
                                                        variant="outlined" 
                                                        margin="normal" 
                                                        size="small"
                                                        sx={classes.formInput} 
                                                        {...field} 
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start" sx={{paddingTop: .2, marginRight: .2}}>$</InputAdornment>,
                                                            inputComponent: NumericFormatCustom,
                                                        }}
                                                        error={errors.bestBasePrice ? true : false}
                                                    />
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.bestBasePrice?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid item  xs={12} sm={4}>
                                            <Controller
                                                name="fuelSurcharge"
                                                control={control}
                                                defaultValue={quoteDetails.TotalSurcharges}
                                                render={({ field }) => 
                                                    <TextField 
                                                        label="Total Surcharge(Fuel + Carbon Tax)" 
                                                        variant="outlined" 
                                                        margin="normal" 
                                                        size="small"
                                                        sx={classes.formInput} 
                                                        {...field} 
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start" sx={{paddingTop: .2, marginRight: .2}}>$</InputAdornment>,
                                                            inputComponent: NumericFormatCustom,
                                                        }}
                                                        error={errors.fuelSurcharge ? true : false}
                                                    />
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.fuelSurcharge?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid item  xs={12} sm={4}>
                                            <Controller
                                                name="totalDropFee"
                                                control={control}
                                                defaultValue={quoteDetails.ExtraFees}
                                                render={({ field }) => 
                                                    <TextField 
                                                        label="Total Extra Fees (Drop Fees)" 
                                                        variant="outlined" 
                                                        margin="normal" 
                                                        size="small"
                                                        sx={classes.formInput} 
                                                        {...field} 
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start" sx={{paddingTop: .2, marginRight: .2}}>$</InputAdornment>,
                                                            inputComponent: NumericFormatCustom,
                                                        }}
                                                        error={errors.totalDropFee ? true : false}
                                                    />
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.totalDropFee?.message}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item  xs={12} sm={4}>
                                            <Controller
                                                name="totalQuote"
                                                control={control}
                                                render={({ field }) => 
                                                    <TextField 
                                                        disabled
                                                        label="Total Quote" 
                                                        variant="outlined" 
                                                        margin="normal" 
                                                        size="small"
                                                        sx={classes.formInput} 
                                                        {...field} 
                                                        error={errors.totalQuote ? true : false}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start" sx={{paddingTop: .2, marginRight: .2}}>$</InputAdornment>,
                                                            inputComponent: NumericFormatCustom,
                                                        }}
                                                        value={Number(bestBasePrice) + Number(fuelSurcharge) + Number(totalDropFee)}
                                                    />
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.totalQuote?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid item  xs={12} sm={4}>
                                            <Controller
                                                name="currency"
                                                control={control}
                                                defaultValue={quoteDetails.Currency}
                                                render={({ field }) => 
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="currencyLabel" sx={{marginTop: 2}}>Currency</InputLabel>
                                                        <Select labelId="currencyLabel" id="currency" sx={classes.selectInput} {...field} label="Currency">
                                                            <MenuItem value="CAD">CAD</MenuItem>
                                                            <MenuItem value="USD">USD</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.currency?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name="dateEquipmentAvailable"
                                                control={control}
                                                defaultValue={dayjs(quoteDetails.DateEquipmentAvailable)}
                                                render={({ field }) => 
                                                    <FormControl fullWidth sx={classes.datePickerStyle}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                slotProps={{
                                                                    textField: {
                                                                        error: false,
                                                                        size: 'small'
                                                                    },
                                                                }}
                                                                id="dateEquipmentAvailable"
                                                                {...field}
                                                                error={errors.dateEquipmentAvailable ? true : false}
                                                                label="Date Equipment Available"
                                                            />
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.dateEquipmentAvailable?.message}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item  xs={12} sm={4}>
                                            <Controller
                                                name="equipmentType"
                                                control={control}
                                                defaultValue={quoteDetails.EquipmentType}
                                                render={({ field }) => 
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="equipmentTypeLabel" sx={{marginTop: 2}}>Equipment Type</InputLabel>
                                                        <Select labelId="equipmentTypeLabel" id="equipmentType" sx={classes.selectInput} {...field} label="Equipment Type">
                                                            <MenuItem value="dryVan">Dry Van</MenuItem>
                                                            <MenuItem value="flatBed">Flat Bed</MenuItem>
                                                            <MenuItem value="skirted">Skirted</MenuItem>
                                                            <MenuItem value="pupTruck">Pup Truck</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.equipmentType?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid item  xs={12} sm={4}>
                                            <Controller
                                                name="trailerType"
                                                control={control}
                                                defaultValue={quoteDetails.TrailerType}
                                                render={({ field }) => 
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="trailerTypeLabel" sx={{marginTop: 2}}>Trailer Type</InputLabel>
                                                        <Select labelId="trailerTypeLabel" id="trailerType" sx={classes.selectInput} {...field} label="Trailer Type">
                                                            <MenuItem value="48Or 53Tandem">Tandem - 48' OR 53'</MenuItem>
                                                            <MenuItem value="48Tandem">Tandem - 48'</MenuItem>
                                                            <MenuItem value="53Tandem">Tandem - 53'</MenuItem>
                                                            <MenuItem value="superB">Super B</MenuItem>
                                                            <MenuItem value="48Tridem">Tridem - 48'</MenuItem>
                                                            <MenuItem value="53Tridem">Tridem - 53'</MenuItem>
                                                            <MenuItem value="48Or 53Tridem">Tridem - 48' OR 53'</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.trailerType?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid item  xs={12} sm={4}>
                                            <Controller
                                                name="transitDays"
                                                control={control}
                                                defaultValue={quoteDetails.TransitDays}
                                                render={({ field }) => 
                                                    <TextField
                                                        label="Your Transit Days" 
                                                        variant="outlined" 
                                                        margin="normal" 
                                                        size="small"
                                                        type="number"
                                                        sx={classes.formInput} 
                                                        {...field} 
                                                        error={errors.transitDays ? true : false}
                                                    />
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.transitDays?.message}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="yourComments"
                                                control={control}
                                                defaultValue={quoteDetails.Comments ? quoteDetails.Comments : ""}
                                                render={({ field }) => 
                                                    <TextField 
                                                        label="Your Comments (Optional)" 
                                                        variant="outlined" 
                                                        multiline
                                                        rows={3}
                                                        margin="normal" 
                                                        sx={classes.formInput}
                                                        {...field} 
                                                        error={errors.yourComments ? true : false}
                                                    />
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.yourComments?.message}
                                            </Typography>
                                        </Grid>
                                        
                                    </Grid>
                                    {!loading ?
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={classes.submitButton}
                                    > 
                                        Submit
                                    </Button>
                                    : <CircularProgress sx={classes.circularProgressStyle}/>}
                                    {/* <Typography sx={classes.confirmationTextStyle}>Confirmation email will be sent to: <span style={{fontWeight: 'bold'}}>{user.UserId}</span></Typography> */}
                                </form>
                                : <LinearProgress />}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
            {/* Pop dialog box for new quote comparison feature */}
            <Dialog open={open} onClose={handleClose}>
                <Box 
                    sx={{ 
                        border: '10px solid #2D54DA',
                    }} 
                >
                    <DialogTitle sx={{ fontWeight: 'bold', color: '#2D54DA'}}>
                        Your Quote Compared to Others
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <p>We have a new feature that will help you get more business and give you insight into how competitive your quotes are!</p>
                        <p>From the <span style={{fontWeight: 'bold'}}>'Open Quotes'</span> and <span style={{fontWeight: 'bold'}}>'Past Quotes'</span> page, when you click on a quote that you have submitted, you will now be able to see how competitive it was compared to other quotes submitted by others on the same load.</p>
                        <p>This also means that as long as the bidding for a load has not closed, you can change your quote rate to make it more competitive. Submit your new rate, refresh the page, and <span style={{fontWeight: 'bold'}}>you will see in real time how much more competitive your quote has become!</span></p>
                        <p><span style={{fontWeight: 'bold'}}>*</span> Please note that this <span style={{fontWeight: 'bold'}}>graph will only appear when there is sufficient comparison data.</span> If there is not sufficient comparison data, nothing will appear.</p>
                        <img src={quoteComparison} width={'100%'} alt='quoteComparisonFeature'/>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                        <Button
                            onClick={handleClose}
                            sx={{
                                backgroundColor: '#2D54DA',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#1A3EA0',
                                }
                            }}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Box>
    );
}

export { EditQuotePage };