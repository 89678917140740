import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { loadActions } from '../../_actions';
import { compareUtil } from '../../_helpers';
import { numberWithCommas } from '../../_helpers';
import { addCapSpace } from '../../_helpers';
import { formatShipmentType } from '../../_helpers';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import dayjs from 'dayjs';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import backgroundImage from '../../_resources/home_page_background.jpg';
import NavMenu from '../../components/NavMenu';
import Footer from '../../components/Footer';

const headCells = [
    {id: 'LoadId', numeric: true, label: 'Load Id'},
    {id: 'QuoteBy', numeric: false, label: 'Quote By'},
    {id: 'Pickup', numeric: false, label: 'From'},
    {id: 'Delivery', numeric: false, label: 'To'},
    {id: 'CrossingBorder', numeric: false, label: 'Crossing Border'},
    {id: 'ShipmentType', numeric: false, label: 'Shipment Type'},
    {id: 'Pallets', numeric: true, label: 'Pallets'},
    {id: 'Weight', numeric: true, label: 'Weight'},
    {id: 'RequestedEquipment', numeric: false, label: 'Requested Equipment'},
    {id: 'RequestedTrailer', numeric: false, label: 'Requested Trailer'},
    {id: 'RequestedShipDate', numeric: false, label: 'Requested Ship Date'},
];

const classes = {
    pageContainer: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100% auto',
        overflow: 'hidden',
        minHeight: '75vh'
    },
    contentContainer: {

    },
    paperStyle: {
        padding: 2
    },
    pageHeader: {
        fontWeight: 'bold',
        color: '#2D54DA'
    },
    dividerStyle: {
        marginTop: 3,
        marginBottom: 3
    },
    tableStyle: {
        //whiteSpace: 'nowrap',
        maxHeight: 500,
    },
    tableHeaderColumnStyle: {
        backgroundColor: '#2D54DA',
        border: '1px solid #26B9F5',
        padding: 1
    },
	tableHeaderStyle: {
		fontWeight: 'bold',
        color: 'white !important',
        fontSize: 14,
        "&:hover": {
            color: 'white',
        },
        '& .MuiTableSortLabel-icon': {
            color: 'white !important',
        },
	},
    tableBodyColumnStyle: {
        border: '1px solid #26B9F5',
        padding: 1,
    },
    tableBodyStyle: {
        cursor: 'pointer',
        backgroundColor: 'white',
        "&:hover": {
            backgroundColor: 'white',
        },
	},
};

function AvailableLoadsPage() {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('QuoteBy');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.authentication.user);
    const loading = useSelector(state => state.load.loading);
    const availableLoads = useSelector(state => state.load.availableLoads);

    useEffect(() => { 
		document.title = "DFQ Vendor - Available Loads";
        dispatch(loadActions.getAvailableLoads(user.UserId));
	}, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onRequestSort = (event, property) => {
        handleRequestSort(event, property);
    };

    const openQuotePage = (load) => {
        console.log(load);
        navigate(`/loads/details/${load.LoadId}`);
    };

    return (
        <Box display="flex" style={{backgroundColor: '#F2F5FD'}}>
        <CssBaseline />
        <NavMenu />
        <Container maxWidth={false} disableGutters sx={{ marginLeft: 2, marginTop: 2, marginRight: 2 }}>
            <Container sx={classes.contentContainer}>
                <Paper elevation={3} sx={classes.paperStyle}>
                    <Typography variant='h5' sx={classes.pageHeader}>Available Loads</Typography>
                    <Divider sx={classes.dividerStyle}/>
                    <Typography sx={{float: 'left'}}>There are <span style={{fontWeight: 'bold'}}>{availableLoads.length}</span> available loads to quote on in your service area</Typography>
                    {availableLoads && availableLoads.length > 0 ?
                        <Typography variant='h5' sx={{float: 'right', fontWeight: 'bold'}}>Click on a row to place a quote</Typography>
                    : null}
                    <TableContainer sx={classes.tableStyle}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={'left'}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                            sx={classes.tableHeaderColumnStyle}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id)}
                                                sx={classes.tableHeaderStyle}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {availableLoads && availableLoads.length > 0 ?
                                compareUtil.stableSort(availableLoads, compareUtil.getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <TableRow hover sx={classes.tableBodyStyle} key={row.LoadId} onClick={() => openQuotePage(row)}>
                                        <TableCell align="left" sx={classes.tableBodyColumnStyle}>{row.LoadId}</TableCell>
                                        <TableCell align="left" sx={classes.tableBodyColumnStyle}>{row.QuoteBy ? dayjs(row.QuoteBy).format('MM/DD/YYYY h:mm A') : ''}</TableCell>
                                        <TableCell align="left" sx={classes.tableBodyColumnStyle}>{row.Pickup}</TableCell>
                                        <TableCell align="left" sx={classes.tableBodyColumnStyle}>{row.Delivery}</TableCell>
                                        <TableCell align="left" sx={classes.tableBodyColumnStyle}>{row.CrossingBorder ? 'Yes' : 'No'}</TableCell>
                                        <TableCell align="left" sx={classes.tableBodyColumnStyle}>{formatShipmentType(row.ShipmentType)}</TableCell>
                                        <TableCell align="left" sx={classes.tableBodyColumnStyle}>{numberWithCommas(row.Pallets)}</TableCell>
                                        <TableCell align="left" sx={classes.tableBodyColumnStyle}>{numberWithCommas(row.Weight)}</TableCell>
                                        <TableCell align="left" sx={classes.tableBodyColumnStyle}>{addCapSpace(row.RequestedEquipment)}</TableCell>
                                        <TableCell align="left" sx={classes.tableBodyColumnStyle}>{addCapSpace(row.RequestedTrailer)}</TableCell>
                                        <TableCell align="left" sx={classes.tableBodyColumnStyle}>{row.RequestedShipDate ? dayjs(row.RequestedShipDate).format('MMM DD, YYYY') : ''}</TableCell>
                                    </TableRow>
                                ))
                                : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={availableLoads.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Container>
        </Container>
        </Box>
    );
}

export { AvailableLoadsPage };