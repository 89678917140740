import { quoteConstants } from '../_constants';
import { quoteService } from '../_services';
import { alertActions } from './';


export const quoteActions = {
    sendQuote,
    getOpenQuotes,
    getPastQuotes,
    getQuoteDetails,
    updateQuoteDetails,
    getQuoteComparisonInfo
};

function sendQuote(data) {
    return dispatch => {
        dispatch(request());
        quoteService.sendQuote(data)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Quote sent!'));
                        window.history.pushState({}, undefined, `/quotes/open/${response.data.Result.Result.QuoteId}`);
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: quoteConstants.SEND_QUOTE_REQUEST } }
    function success() { return { type: quoteConstants.SEND_QUOTE_SUCCESS } }
    function failure() { return { type: quoteConstants.SEND_QUOTE_FAILURE } }
}

function getOpenQuotes(userId) {
    return dispatch => {
        dispatch(request());
        quoteService.getOpenQuotes(userId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                        //dispatch(alertActions.success('Quote sent!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: quoteConstants.GET_OPEN_QUOTES_REQUEST } }
    function success(openQuotes) { return { type: quoteConstants.GET_OPEN_QUOTES_SUCCESS, openQuotes } }
    function failure() { return { type: quoteConstants.GET_OPEN_QUOTES_FAILURE } }
}

function getPastQuotes(userId) {
    return dispatch => {
        dispatch(request());
        quoteService.getPastQuotes(userId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                        //dispatch(alertActions.success('Quote sent!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: quoteConstants.GET_PAST_QUOTES_REQUEST } }
    function success(pastQuotes) { return { type: quoteConstants.GET_PAST_QUOTES_SUCCESS, pastQuotes } }
    function failure() { return { type: quoteConstants.GET_PAST_QUOTES_FAILURE } }
}

function getQuoteDetails(quoteId) {
    return dispatch => {
        dispatch(request());
        quoteService.getQuoteDetails(quoteId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                        //dispatch(alertActions.success('Quote sent!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: quoteConstants.GET_QUOTE_DETAILS_REQUEST } }
    function success(quoteDetails) { return { type: quoteConstants.GET_QUOTE_DETAILS_SUCCESS, quoteDetails } }
    function failure() { return { type: quoteConstants.GET_QUOTE_DETAILS_FAILURE } }
}

function updateQuoteDetails(data) {
    return dispatch => {
        dispatch(request());
        quoteService.updateQuoteDetails(data)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Quote updated!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: quoteConstants.UPDATE_QUOTE_DETAILS_REQUEST } }
    function success() { return { type: quoteConstants.UPDATE_QUOTE_DETAILS_SUCCESS } }
    function failure() { return { type: quoteConstants.UPDATE_QUOTE_DETAILS_FAILURE } }
}

function getQuoteComparisonInfo(quoteId) {
    return dispatch => {
        dispatch(request());
        quoteService.getQuoteComparisonInfo(quoteId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: quoteConstants.GET_QUOTE_COMPARISON_INFO_REQUEST } }
    function success(quoteComparisonInfo) { return { type: quoteConstants.GET_QUOTE_COMPARISON_INFO_SUCCESS, quoteComparisonInfo } }
    function failure() { return { type: quoteConstants.GET_QUOTE_COMPARISON_INFO_FAILURE } }
}